module.exports = [{
      plugin: require('/Users/theefunklord/Sites/Creative Confederation2/domains/creativeconfederation.com/private_html/beta.2020/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/theefunklord/Sites/Creative Confederation2/domains/creativeconfederation.com/private_html/beta.2020/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/theefunklord/Sites/Creative Confederation2/domains/creativeconfederation.com/private_html/beta.2020/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/theefunklord/Sites/Creative Confederation2/domains/creativeconfederation.com/private_html/beta.2020/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/theefunklord/Sites/Creative Confederation2/domains/creativeconfederation.com/private_html/beta.2020/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
